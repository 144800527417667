/**
 * 1. Dependencies
 *
 * This section can pull in mixins, other modules, helpers, etc
 *
 * 2. Configuration
 *
 * This section defines default options for this module.
 * For settings, the order is as follows (ordered highest priority to lowest):
 *
 * Namespace - data-[namespace] key for grabbing inline options
 *
 * - data-[namespace]-key attributes on the node
 * - data-[namespace] attribute on the node
 * - user passed in settings (into the factory function)
 * - default settings
 *
 * 3. Module
 *
 * This section defines the code for your module.
 * It should always be scoped to one node.
 *
 * 4. Factory function
 *
 * This section defines the default exported function for your module.
 * This code should new up an instance of your module for each node that will consume it.
 */

// 1. Dependencies
import $ from 'jquery';
import { events, nodeConfig } from '@/mixins';

// 2. Configuration
const NAMESPACE = 'feature';
const DEFAULT_SELECTOR = '.js-feature';
const DEFAULT_SETTINGS = {
	option: 'value',
	anotherOption: 'some-value',
	active: true
};

// 3. Module
class FeatureModule {
	constructor(node, config) {
		this.$node = $(node);
		this.config = config;
		this.dotDistanceFromCenter = 0;
	}

	determineIfSafari() {
		const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

		if (isSafari) {
			$('html').addClass('safarie');
		} else {
			$('html').removeClass('safarie');
		}
	}

	getDotDistance() {
		var dotDimensions = $('.feature_logos_dot')[0].getBoundingClientRect();

		this.dotDistanceFromCenter = (dotDimensions.left + (dotDimensions.width / 2)) - ($(window).width() / 2);
		this.dotDistanceFromCenter = this.dotDistanceFromCenter + (26 * 8 / 2);
	}

	onScroll(event) {
		const isScrolled = document.documentElement.scrollTop > 0 || document.body.scrollTop > 0;

		if (isScrolled) {
			$('html').addClass('scrolling');
		} else {
			$('html').removeClass('scrolling');
		}

		var featureDimensions = $('.feature')[0].getBoundingClientRect();
		var featureContentDimensions = $('.feature_content_body')[0].getBoundingClientRect();
		var percentScrolled = (featureDimensions.bottom - $(window).height()) / (featureDimensions.height - $(window).height());
		var progress = 1 - percentScrolled;
		var metBottom = featureDimensions.bottom - $(window).height() <= 0;

		if (!metBottom) {
			$('.feature').css({
				'--progress': 1 - percentScrolled,
				'--translate': `${progress * 100 * .66}%`,
			});

			$('.feature_logos_wrapper').css('transform', `translateX(${this.dotDistanceFromCenter * progress * -1}px)`);
			$('.feature_logos_inner').css('gap', `${(8 + (26 - (percentScrolled * 26)) * 8)}px`);
			$('.feature_logos').css('transform', `scale(${1 + 13 - (percentScrolled * 13)})`);
			$('.feature_logos_dot_circle').css('transform', `scale(${1 + 13 - (percentScrolled * 13)})`);

			if (featureDimensions.top * -1 <= featureDimensions.height / 3) {
				$('.feature_logo_start').css('opacity', 1 - ((featureDimensions.top * -1) / (featureDimensions.height / 3)));
				$('.feature_logos_dot_inner').css('opacity', 0);
			} else if (featureDimensions.top * -1 <= featureDimensions.height / 3 * 2) {
				$('.feature_logo_start').css('opacity', 0);
				$('.feature_logo_middle').css('opacity', 1 - (featureDimensions.top * -1) / (featureDimensions.height / 3 * 2));
				$('.feature_logos_dot_inner').css({
					'opacity': progress,
					'transform': `scale(${progress})`
				});
			} else {
				$('.feature_logo_start').css('opacity', 0);
				$('.feature_logo_middle').css('opacity', 0);
			}

			if (featureContentDimensions.top <= $(window).height() / 2) {
				$('.feature_title').css('--contentProgress', 1 - featureContentDimensions.top / ($(window).height() / 2));
			} else {
				$('.feature_title').css('--contentProgress', 0);
			}
		} else {
			progress = 1;
			percentScrolled = 0;

			$('.feature').css({
				'--progress': 1 - percentScrolled,
				'--translate': `${progress * 100 * .66}%`,
			});

			$('.feature_logos_wrapper').css('transform', `translateX(${this.dotDistanceFromCenter * progress * -1}px)`);
			$('.feature_logos_inner').css('gap', `${(8 + (26 - (percentScrolled * 26)) * 8)}px`);
			$('.feature_logos').css('transform', `scale(${1 + 13 - (percentScrolled * 13)})`);
			$('.feature_logos_dot_circle').css('transform', `scale(${1 + 13 - (percentScrolled * 13)})`);

			$('.feature_logos_dot_inner').css({
				'opacity': progress,
				'transform': `scale(${progress})`
			});

			$('.feature_logo_start').css('opacity', 0);
			$('.feature_logo_middle').css('opacity', 0);

			$('.feature_title').css('--contentProgress', 1);
		}
	}

	bindUI() {
		window.addEventListener('scroll', this.onScroll.bind(this));
		window.addEventListener('resize', this.onScroll.bind(this));
	}

	init() {
		this.bindUI();
		this.determineIfSafari();
		this.getDotDistance();
		this.onScroll();
	}
}

// 4. Factory Function
export default function factory(selector = DEFAULT_SELECTOR, settings = {}) {
	let listNode = document.querySelectorAll(selector);

	if (!listNode.length) return;

	return Array.from(listNode).map((node) => {
		let config = nodeConfig(node, NAMESPACE, settings, DEFAULT_SETTINGS);
		let module = new FeatureModule(node, config);

		module.init();

		return module;
	});
}
